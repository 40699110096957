import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Welcome from './components/Welcome';
import About from './components/About';
import Contact from './components/Contact';
import Server from './components/Server'
import Share from './components/Share'
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    CssBaseline,
    IconButton,
    AppBar,
    Toolbar,
    Typography,
    useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@mui/material/styles";

const App = () => {
    
    const [open, setOpen] = React.useState(false);

    const handleDrawerToggle = () => {
      setOpen(!open);
    };
  
    return (
      <Router>
        {/* CssBaseline to reset default margins and paddings */}
        <CssBaseline />
  
        {/* AppBar at the top */}
        <AppBar position="fixed">
          <Toolbar>
            {/* IconButton to open the Drawer */}
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap>
                {process.env.REACT_APP_NAME}
            </Typography>
          </Toolbar>
        </AppBar>
  
        {/* Drawer component */}
        <Drawer
          variant="temporary"
          anchor="left"
          open={open}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            '& .MuiDrawer-paper': { width: '200px' },
          }}
        >
          <Toolbar /> {/* To account for the AppBar height */}
          <List>
            <ListItem button component={Link} to="/" onClick={handleDrawerToggle}>
              <ListItemText primary="Welcome" />
            </ListItem>
            <ListItem button component={Link} to="/about" onClick={handleDrawerToggle}>
              <ListItemText primary="About" />
            </ListItem>
            <ListItem button component={Link} to="/server" onClick={handleDrawerToggle}>
              <ListItemText primary="Server" />
            </ListItem>
            <ListItem button component={Link} to="/share" onClick={handleDrawerToggle}>
              <ListItemText primary="Share" />
            </ListItem>
            <ListItem button component={Link} to="/contact" onClick={handleDrawerToggle}>
              <ListItemText primary="Contact" />
            </ListItem>
          </List>
        </Drawer>
  
        {/* Main content */}
        <main
          style={{
            marginTop: '64px', // Height of the AppBar
            padding: '16px',
          }}
        >
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/about" element={<About />} />
            <Route path="/server" element={<Server />} />
            <Route path="/share" element={<Share />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>
      </Router>
    );

};

export default App;