import React from 'react';
import Image from './Image';
import Icon from './Icon';
import './Icon.css'

const Share = () => {
    return (
        <div>
            <h3>Share the application</h3>
            <div>
                <div><Image img="qr_vmbase_welcome.png" height="200px"/></div>
            </div>
        </div>
    );
};

export default Share;