import React from 'react';
import Image from './Image';

const Icon = ({img}) => {
    
    return (
         <Image img={img} height="24px"/>
        );
};

export default Icon;