import React from 'react';

const Server = () => {
    return (
        <div>
            <div>
            <h3>Server API</h3>
                <p>You can test the server API here: <a href={`${process.env.REACT_APP_API}`} target="_blank">Swagger API docs</a>.</p> 
            </div>
        </div>
    );
};

export default Server;