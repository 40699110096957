// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav ul {
    display: flex;
    list-style: none;
    padding: 0;
    background-color: #282c34;
    margin: 0;
}

nav ul li {
    margin: 0 15px;
}

nav ul li a {
    text-decoration: none;
    color: white;
    font-weight: bold;
}

nav ul li a:hover {
    text-decoration: underline;
}

div {
    padding: 20px;
    font-family: Arial, sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,yBAAyB;IACzB,SAAS;AACb;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":["nav ul {\n    display: flex;\n    list-style: none;\n    padding: 0;\n    background-color: #282c34;\n    margin: 0;\n}\n\nnav ul li {\n    margin: 0 15px;\n}\n\nnav ul li a {\n    text-decoration: none;\n    color: white;\n    font-weight: bold;\n}\n\nnav ul li a:hover {\n    text-decoration: underline;\n}\n\ndiv {\n    padding: 20px;\n    font-family: Arial, sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
