import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Welcome = () => {
    return (
        <div>
            <div>
                <h3>Welcome to the {process.env.REACT_APP_NAME}</h3>
                <p>If you see this page, the virtual server is up and running... Quite interesting!</p>
                <p><ArrowForwardIosIcon /> Please try the different pages from the menu.</p>
            </div>
        </div>
    );
};

export default Welcome;