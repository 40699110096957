import React from 'react';



const Image = ({img, height}) => {
    return (
            <img src={`${process.env.PUBLIC_URL}/${img}`} style={{  height: `${height}`}} alt={img} />
    );
};

export default Image;
