import React from 'react';
import Image from './Image';
import Icon from './Icon';
import './Icon.css'
import './About.css'

const About = () => {
    const items = [
        { id: 1, title: 'Azure' },
        { id: 2, title: 'Terraform' },
        { id: 3, title: 'Docker Hub' },
        { id: 4, title: 'GitHub' },
        { id: 5, title: 'https' },
        { id: 6, title: 'Swagger' },
        { id: 7, title: 'Node' },
        { id: 8, title: 'React' },
        { id: 9, title: 'Nginx' },
        { id: 10, title: 'Ubuntu' }
    ];
    const columnCount=4; 

    return (
        <div>
            <h3>Application powered with (highlights)</h3>
            {/*
            <ul className='compact-list'>
                {items.map(item => (
                    <li key={item.id}>
                        <p><Icon img={item.title + '.png'}/> {item.title}</p>
                    </li>
                ))}
            </ul> 
            */}
            <table className="compact-table">
  <tbody>
    {items.reduce((rows, item, index) => {
      // Every 3rd item starts a new row
      if (index % columnCount === 0) rows.push([]);
      rows[rows.length - 1].push(item);
      return rows;
    }, []).map((row, rowIndex) => (
      <tr key={rowIndex}>
        {row.map(item => (
          <td key={item.id}>
            <p>
              <Icon img={`${item.title}.png`} /> {item.title}
            </p>
          </td>
        ))}
        {/* Fill remaining columns if the row has less than 3 items */}
        {row.length < columnCount &&
          Array.from({ length: columnCount - row.length }, (_, idx) => (
            <td key={`empty-${rowIndex}-${idx}`}></td>
          ))}
      </tr>
    ))}
  </tbody>
</table>

            <div>
                <h3>Architecture</h3>
                <div><Image img="Architecture.png" height="360px"/></div>
            </div>
        </div>
    );
};

export default About;