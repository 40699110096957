import React from 'react';

const Contact = () => {
    return (
        <div>
            <h1>Contact</h1>
            <p>If you have any comments or questions, feel free to <a href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}>email us</a>.</p>
        </div>
    );
};

export default Contact;