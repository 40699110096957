// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compact-list {
    font-size: 16px; /* Smaller font size */
    line-height: 1.0; /* Reduce gap between lines */
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    list-style: none; /* Optional: removes bullets */
  }
  
  .compact-list li {
    margin: 0;
    padding: 0px 0; /* Adjusts space between list items */
  }

  .compact-table {
    width: 100%;
    border-collapse: collapse; /* Removes space between cells */
  }
  
  .compact-table td {
    padding: 2px; /* Minimal padding */
    margin: 0; /* No margin */
    height: 1px; /* Minimum height */
    text-align: center;  
    border: 0px solid #ccc;  
  }
  
  .compact-table tr {
    height: 1px; /* Minimum height for rows */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/About.css"],"names":[],"mappings":"AAAA;IACI,eAAe,EAAE,sBAAsB;IACvC,gBAAgB,EAAE,6BAA6B;IAC/C,SAAS,EAAE,0BAA0B;IACrC,UAAU,EAAE,2BAA2B;IACvC,gBAAgB,EAAE,8BAA8B;EAClD;;EAEA;IACE,SAAS;IACT,cAAc,EAAE,qCAAqC;EACvD;;EAEA;IACE,WAAW;IACX,yBAAyB,EAAE,gCAAgC;EAC7D;;EAEA;IACE,YAAY,EAAE,oBAAoB;IAClC,SAAS,EAAE,cAAc;IACzB,WAAW,EAAE,mBAAmB;IAChC,kBAAkB;IAClB,sBAAsB;EACxB;;EAEA;IACE,WAAW,EAAE,4BAA4B;EAC3C","sourcesContent":[".compact-list {\n    font-size: 16px; /* Smaller font size */\n    line-height: 1.0; /* Reduce gap between lines */\n    margin: 0; /* Remove default margin */\n    padding: 0; /* Remove default padding */\n    list-style: none; /* Optional: removes bullets */\n  }\n  \n  .compact-list li {\n    margin: 0;\n    padding: 0px 0; /* Adjusts space between list items */\n  }\n\n  .compact-table {\n    width: 100%;\n    border-collapse: collapse; /* Removes space between cells */\n  }\n  \n  .compact-table td {\n    padding: 2px; /* Minimal padding */\n    margin: 0; /* No margin */\n    height: 1px; /* Minimum height */\n    text-align: center;  \n    border: 0px solid #ccc;  \n  }\n  \n  .compact-table tr {\n    height: 1px; /* Minimum height for rows */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
